import async from "../components/Async";

import {
  faCamera,
  faFile,
  faHome,
  faSignInAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

// Auth
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));

// Dashboards
const Dashboard = async(() => import("../pages/dashboard/Dashboard"));
const Cameras = async(() => import("../pages/cameras/Cameras"));
const Lockers = async(() => import("../pages/lockers"));
const Profile = async(() => import("../pages/profile/Profile"));
const Statistics = async(() => import("../pages/statistics/"));
const History = async(() => import("../pages/history/"));

// Routes

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  slug: "dashboard",
  header: "Main",
  icon: faHome,
  component: Dashboard,
  children: null,
};

const profileRoutes = {
  path: "/profile/",
  slug: "profile",
  name: "Profile",
  icon: faUser,
  hideFromSidebar: true,
  children: [
    {
      path: "/profile/",
      name: "Profile",
      component: Profile,
    },
  ],
};

const lockerRoutes = {
  path: "/lockers/:slug",
  name: "Lockers",
  slug: "lockers",
  icon: faFile,
  component: Lockers,
  children: null,
};

const statisticsRoutes = {
  path: "/statistics/:slug",
  name: "Statistics",
  slug: "statistics",
  icon: faFile,
  component: Statistics,
  children: null,
};

const historyRoutes = {
  path: "/history/:slug",
  name: "History",
  slug: "history",
  icon: faFile,
  component: History,
  children: null,
};

const cameraRoutes = {
  path: "/cameras/",
  name: "Cameras",
  slug: "cameras",
  icon: faCamera,
  component: Cameras,
  children: null,
};

const authRoutes = {
  path: "/",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/",
      name: "Sign In",
      component: SignIn,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  lockerRoutes,
  cameraRoutes,
  profileRoutes,
  statisticsRoutes,
  historyRoutes,
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default dashboard;
