import React, { useState } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";

import { Badge, Collapse } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import routes from "../routes/index";

import { ReactComponent as LockerestLogo } from "../assets/img/brands/lockerest_logo.svg";

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen =
      route.open ||
      (pathName.includes("/lockers/") && route.path.includes("/lockers/")) ||
      (pathName.includes("/history/") && route.path.includes("/history/")) ||
      (pathName.includes("/statistics/") &&
        route.path.includes("/statistics/"));
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to, dispatch }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink
          to={to}
          className="sidebar-link"
          activeClassName="active"
          onClick={() => window.innerWidth < 991 && dispatch(toggleSidebar())}
        >
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({
  location,
  sidebar,
  lockers,
  username,
  dispatch,
  access,
}) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));
  const toggle = (index) => {
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
          href="/"
        >
          <LockerestLogo />
        </a>

        <div className="sidebar-user">
          {/* <img
            src={avatar}
            className="img-fluid rounded-circle mb-2"
            alt="Ergo Tamm"
          /> */}
          <div className="font-weight-bold">{username}</div>
          {/* <small>Haldur</small> */}
        </div>
        <ul className="sidebar-nav">
          {routes.map((category, index) => {
            return category.hideFromSidebar ||
              !access.includes(category.slug) ? null : (
              <React.Fragment key={index}>
                {category.header ? (
                  <li className="sidebar-header">{category.header}</li>
                ) : null}
                {lockers.length && category.path.includes(":slug") ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                  >
                    {lockers
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(({ name, slug }, index) => (
                        <SidebarItem
                          key={index}
                          name={name}
                          to={`/${category.path.split("/")[1]}/${slug}`}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeText}
                          dispatch={dispatch}
                        />
                      ))}
                  </SidebarCategory>
                ) : category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                  >
                    {category.children.map((route, index) => (
                      <SidebarItem
                        key={index}
                        name={route.name}
                        to={route.path}
                        badgeColor={route.badgeColor}
                        badgeText={route.badgeText}
                        dispatch={dispatch}
                      />
                    ))}
                  </SidebarCategory>
                ) : (
                  <SidebarItem
                    name={category.name}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    dispatch={dispatch}
                  />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    lockers: store.access.lockers,
    access: store.access.routes,
    username: store.access.username,
  }))(Sidebar)
);
