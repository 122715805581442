import React, { Fragment } from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";
import LockerestLogo from "../assets/img/brands/lockerest_logo.svg";

const Auth = ({ children }) => (
  <Fragment>
    <Main className="h-100 w-100" style={{ backgroundColor: "black" }}>
      <Container className="h-100">
        <Row className="h-100">
          <Col sm={"10"} md={"8"} lg="6" className={"mx-auto d-table h-100"}>
            <div className={"d-table-cell align-middle"}>
              <div className={"text-center mt-4"}>
                <img
                  src={LockerestLogo}
                  alt="Lockerest"
                  style={{ maxWidth: "200px", margin: "30px 0" }}
                />
              </div>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </Main>
  </Fragment>
);

export default Auth;
