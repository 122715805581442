import React from "react";
import { createRoot } from "react-dom/client";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "react-datetime/css/react-datetime.css";

import App from "./App";

// Render app in `#root` element
createRoot(document.getElementById("root")).render(<App />);
