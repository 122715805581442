const initialState = {
  logged_in: sessionStorage.getItem("logged_in") === "true",
  lockers: sessionStorage.getItem("lockers") ? JSON.parse(sessionStorage.getItem("lockers")) : "",
  username: sessionStorage.getItem("username"),
  user_id: sessionStorage.getItem("user_id"),
  routes: sessionStorage.getItem("routes") ? JSON.parse(sessionStorage.getItem("routes")) : ""
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case "access":
      const { lockers, username, user_id, routes } = actions.value
      if(actions.value !== "" ){
        sessionStorage.setItem("logged_in", true)
        sessionStorage.setItem("lockers", JSON.stringify(lockers))
        sessionStorage.setItem("routes", JSON.stringify(routes))
        sessionStorage.setItem("username", username)
        sessionStorage.setItem("user_id", user_id)
        return {
          ...state,
          logged_in: true,
          lockers,
          routes,
          username,
          user_id
        }
      } else {
        sessionStorage.setItem("logged_in", false)
        sessionStorage.setItem("lockers", "")
        sessionStorage.setItem("routes", "")
        sessionStorage.setItem("username", "")
        sessionStorage.setItem("user_id", "")
        return {
          ...state,
          logged_in: false,
          lockers: [],
          routes: [],
          username: "",
          user_id: ""
        }
      }

    default:
      return state;
  }
}
