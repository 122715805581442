import * as types from "../constants";

const themes = {
  modern: {
    primary: "#3B7DDD",
    secondary: "#6f42c1",
    tertiary: "#669ae5",
    success: "#28a745",
    info: "#20c997",
    warning: "#fd7e14",
    danger: "#dc3545",
    dark: "#153d77"
  },
};

const initialState = {
  currentTheme: themes.modern,
  themes: themes
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.THEME_TOGGLE:
      return {
        ...state,
        currentTheme: state.themes[actions.payload]
      };

    default:
      return state;
  }
}
