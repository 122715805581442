import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { dashboard as dashboardRoutes, page as authRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import Auth from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

const childRoutes = ({ Layout, routes }) => {
  return routes.map(({ children, path, component: ParentComponent }, index) => {
    return children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => {
        return Component ? (
          <Route
            key={index}
            path={path}
            exact
            render={(props) => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        ) : null;
      })
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <ParentComponent {...props} />
          </Layout>
        )}
      />
    );
  });
};

const HasAcccess = connect((store) => ({
  access: store.access,
}))(({ access }) => {
  return access.logged_in === true
    ? childRoutes({
        Layout: DashboardLayout,
        routes: dashboardRoutes,
        access: access.routes,
      })
    : childRoutes({ Layout: Auth, routes: authRoutes });
});

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <HasAcccess />
          <Route
            path="*"
            render={() => (
              <Auth>
                <Page404 />
              </Auth>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
