import React from "react";
import { Container } from "reactstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
    </Container>
  </footer>
);

export default Footer;
